/* =========== Page conetnt CSS Start =============== */


.content-wrapper {
    background: #F6F8FA;
    padding: 40px;
}

.dashboard-info {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.20); */
    padding-bottom: 40px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    gap: 20px;
}
.dashboard-heading-data {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    gap: 40px;
}

.user-id h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
    margin: 0 0 5px;
}

.user-id a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.176px;
}

.user-id span {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.176px;
}

.block-heading {
    padding: 30px 0;
    font-size: 16px;
    margin-right: 20px;
}

.block-heading h3 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
}

.faq-block:not(:last-child) {
    margin-bottom: 25px;
    border-bottom: 1px solid #ccc6c6;
    padding-bottom: 15px;
}

.faq-wrapper {
    background: #F8FAFC;
    padding: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid #94A3B8;
}

.faq-block.active .faq-heading h6 {
    margin: 0;
}

.faq-block.active .faq-heading {
    margin-bottom: 25px;
}

.faq-heading h6 {
    margin: 0 0 10px;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.176px;
    color: #000;
}

.faq-content p {
    margin: 10px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.154px;
    color: #000;
}

.faq-block.active .faq-heading span {
    background-color: #37657F;
}

.faq-heading span {
    background: #94A3B8;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    font-size: 14px;
}

.rtl-faq-heading span {
    left: 0 !important;
    right: auto !important;
}

.faq-heading span svg {
    fill: #fff;
}

.faq-heading {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
}

.faq-content {
    color: #686a6f;
    font-size: 16px;
    display: none;
    margin-bottom: 40px;
}

.faq-block.active .faq-content {
    display: block;
}

faq-heading svg.hide {
    display: block;
}

.faq-block.active .faq-heading svg.show {
    display: block;
}

.faq-block.active .faq-heading svg.hide {
    display: none;
}

.faq-heading svg.show {
    display: none;
}

.block-heading svg,
.dashboard-info svg,
.block-heading img,
.dashboard-info img {
    border-radius: 7px;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
    font-size: 16px;
}

@media screen and (max-width:1199px) {
    /* .page-wrapper {
        max-width: 700px;
    } */

}

@media screen and (max-width:991px) {
    /* .page-wrapper {
        max-width: 490px;
    } */

    /* .faq-heading h6 {
        max-width: 325px;
    } */
}

@media screen and (max-width:767px) {

    .faq-heading span {
        right: -10px;
    }

    .content-wrapper {
        padding: 25px;
    }

    .faq-wrapper {
        padding: 20px;
    }

    /* .faq-heading h6 {
        max-width: 205px;
    } */

    .faq-heading span {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width:575px) {
/* 
    .faq-heading h6 {
        max-width: 194px;
    } */

    .faq-heading h6 {
        width: 100%;
        max-width: 250px;
    }
}

/* =========== Page Content CSS End =============== */