*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: "Corbel V2"; */
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    box-sizing: border-box;
    /* overflow: hidden; */
}

img {
    max-width: 100%;
    height: auto;
}

/* .main-wrapper-box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
} */

.main-dashboard-wrapper {
    /* max-width: 1100px;
    margin: auto; */
    display: flex;
}
/* 
.content-wrapper {
    height: calc(100vh - 88px);
    overflow-x: hidden;
} */

/* =========== Page Side bar CSS Start =============== */

.page-sidebar {
    width: 100%;
    min-width: 280px;
    background: #205e83;
    position: relative;
    height: 100vh;
    /* position: sticky; */
    top: 0;
    z-index: 6;
}

.site-logo {
    padding: 40px 35px;
}

.page-sidebar .site-logo a {
    display: block;
}

.site-logo img.desktop-logo {
    display: block;
    border-radius: 7px;
}

.site-logo img.mobile-logo {
    display: none;
}

.desktop-logo-back{
    background-color: white;
    border-radius: 8px;
}

.page-sidebar .site-logo img.desktop-logo {
    width: 150px;
    height: 70px;
    margin: auto;
    object-fit: cover;
}

.info-block {
    display: flex;
    gap: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    padding: 0 0 25px;
    margin: 0 35px 25px;
    line-height: 22px;
}

.info-block img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.inner-info a {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: -0.132px;
}

.inner-info div {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: -0.132px;
}

.inner-info p {
    margin: 8px 0 0;
    color: #fff;
    line-height: 24px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: -0.132px;

}

button.log-out-button {
    background: #fff;
    border: none;
    min-width: 150px;
    max-width: 150px;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    font-family: "Corbel V2";
    color: #DC2626;
}

button.log-out-button span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

button.log-out-button svg {
    margin-left: 10px;
    fill: #DC2626;
}

.log-out-block {
    background:  rgba(246, 248, 250, 0.10);
    padding: 25px;
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
}

.navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    height: calc(100vh - 360px);
    margin-top: 12px
}

.navigation::-webkit-scrollbar {
    width: 5px;
}

.navigation::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
}

.navigation::-webkit-scrollbar-thumb {
    background: rgb(237, 239, 241, 0.8);
    border-radius: 10px;
}

.main-nav ul li svg {
    fill: #fff;
    margin-right: 20px;
    height: 24px;
    width: 24px;
}

.main-nav ul {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
}

.main-nav ul li {
    position: relative;
}

.main-nav ul li.current span.nav-icon {
    top: 10px;
}

.main-nav ul li a:hover,
.main-nav ul li span.nav-span:hover {
    background: #fff;
    color: #205e83;
}

.main-nav ul li div:hover,
.main-nav ul li span.nav-span:hover {
    background: #fff;
    color: #205e83;
}

.main-nav > ul > li.current > a, ul.sub-nav li.current a, .main-nav ul li.current span.nav-span {
    background-color: #fff;
    color: #205e83;
    cursor: pointer;
}

.main-nav > ul > li.current > div, ul.sub-nav li.current div, .main-nav ul li.current span.nav-span {
    background-color: #fff;
    color: #205e83;
    cursor: pointer;
}

.main-nav > ul > li.current > a > svg, ul.sub-nav li.current a svg, .main-nav ul li.current span.nav-span svg {
    fill: #205e83;
}

.main-nav > ul > li.current > div > svg, ul.sub-nav li.current div svg, .main-nav ul li.current span.nav-span svg {
    fill: #205e83;
}

.main-nav ul li a:hover svg,
.main-nav ul li span.nav-span:hover svg {
    fill: #205e83;
}

.main-nav ul li div:hover svg,
.main-nav ul li span.nav-span:hover svg {
    fill: #205e83;
}

.main-nav ul li a,
.main-nav ul li span.nav-span {
    min-height: 45px;
}

.main-nav ul li div,
.main-nav ul li span.nav-span {
    min-height: 45px;
}

ul.sub-nav {
    padding: 0;
    margin: 0;
    display: none;
}

.main-nav ul li a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.132px;
}

.main-nav ul li div {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.132px;
}

ul.sub-nav li a {
    color: #fff;
    padding: 0 35px 0 55px;
}

.rtl-sub {
    padding: 0 55px 0 35px !important;
}

ul.sub-nav li div {
    color: #fff;
    padding: 0 35px 0 55px;
}

.main-nav ul li span.nav-span {
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.132px;
}

.main-nav ul li span.nav-icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 10px;
}

/* .main-nav ul li span.nav-span svg {
    padding-right: 20px;
} */

/* .main-nav ul li.current span {
    top: 12px;
    padding: 7px 35px;
    right: -20px;
} */

li.current ul.sub-nav {
    display: block;
}

svg.close {
    display: block;
}

li.current svg.close {
    display: block;
    transform: rotate(180deg);
}

li.current svg.open {
    display: block;
}

svg.open {
    display: none;
}

.search-bar {
    margin: 0 35px;
}

.search-bar input {
    padding: 13px 20px 13px 45px;
    width: 100%;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
    background: #fff url(../searchimage.png) no-repeat 10px center;
    font-size: 14px;
    font-weight: 400;
    font-family: "Corbel V2";
}

.search-bar input:focus-visible {
    outline: none;
}

/* ============== Side Menu Respomsive ============= */

.hide-bar {
    width: 32px;
    height: 32px;
    background: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 120px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
}

.hide-bar svg {
    fill: #3c6f8d;
    font-size: 14px !important;
}

.mobile-menu svg {
    font-size: 22px;
}

.mobile-menu svg.close-nav,
.mobile-menu svg.nav-menu {
    display: none;
    z-index: 2;
    position: absolute;
    top: 32px;
    left: 30px;
}

.open-hide-bar .page-sidebar {
    min-width: 120px;
    max-width: 120px;
}

.open-hide-bar .hide-bar svg {
    transform: rotate(180deg);
}

.open-hide-bar .hide-nav {
    display: none;
}

.open-hide-bar .inner-info {
    display: none;
}

.open-hide-bar .site-logo {
    width: 100px;
    margin: auto;
    padding: 20px;
}

.open-hide-bar .log-out-block {
    max-width: 70px;
    padding: 5px;
}

.open-hide-bar button.log-out-button {
    min-width: 60px;
}

.open-hide-bar button.log-out-button svg {
    padding-left: 0;
}

.open-hide-bar .search-bar input {
    min-width: 50px;
    max-width: 50px;
}

.open-hide-bar .main-nav ul li svg, .open-hide-bar .main-nav ul li span.nav-span svg {
    padding: 0;
    margin: 0;
}

.open-hide-bar .main-nav > ul > li > a, .open-hide-bar .main-nav ul li span.nav-span {
    padding: 0;
    justify-content: center;
}

.open-hide-bar .main-nav > ul > li > div, .open-hide-bar .main-nav ul li span.nav-span {
    padding: 0;
    justify-content: center;
}

.open-hide-bar img.mobile-logo {
    display: block;
    margin: auto;
    border-radius: 7px;
}

.open-hide-bar img.desktop-logo {
    display: none;
}

/* =========== Page Side bar CSS End =============== */


/* =========== Page Header CSS Start =============== */

.page-wrapper {
    width: 100%;
    /* min-width: 920px; */
    /* width: calc(100% - 5px); */
    /* z-index: -1; */
}

.header-wrapper {
    background: #fff;
    padding: 30px 40px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}

.page-title h6 {
    font-size: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.198px;
}

.header-right ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 15px;
}

button.btn.button-green-start-learning {
    background: #205e83;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-family: "Corbel V2";
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 16px;
    padding: 8;
    white-space: nowrap;
}

button.btn.button-green {
    background: #205e83;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-family: "Corbel V2";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 16px;
}

button.btn.button-green-complete {
    background: rgb(52, 211, 153);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-family: "Corbel V2";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 16px;
}

button.btn.button-assessment-test {
    background: #F1F5F9;
    border-radius: 8px;
    color: #37657F;
    font-family: "Corbel V2";
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 8;
    white-space: nowrap;
}

.page-title h6 svg {
    margin-right: 15px;
    font-size: 20px;
}

.btn.button-green svg {
    fill: #fff;
    margin-left: 10px;
}

ul.page-info li svg {
    fill: #fff;
    font-size: 18px;
    vertical-align: bottom;
}
/* 
ul li.bg-gray {
    background: #205e83;
    border: none;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 18px;
} */

button.btn.button-green:hover{
    background-color: #747e8a;
}

a.btn.button-red.button-edit {
    background: #DC2626;
    border: 1px solid #DC2626;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
}

a.btn.button-red.button-edit:hover {
    background: transparent;
    border: 1px solid #DC2626;
    color: #DC2626;
}

.modal-header.log-out-modal-header {
    padding: 40px 40px 0 0;
}

.fixed-side-bar {
    position: fixed;
    right: 50px;
    bottom: 40px;
    /* background: #fff; */
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 10px; */
    /* box-shadow: 0px 0px 10px #ccc; */
}

/* =========== Page Header CSS End =============== */

@media screen and (max-width:1199px) {
    /* .main-dashboard-wrapper {
        max-width: 960px;
    } */

    .page-sidebar {
        max-width: 260px;
    }

    button.log-out-button {
        min-width: 140px;
        max-width: 140px;
    }

    .page-wrapper {
        min-width: 710px;
    }
}

/* @media screen and (max-width: 1023px) {
.page-wrapper {
    min-width: 410px;
    max-width: 670px;
    width: 100%;
} 
}*/

@media screen and (max-width:991px) {
    /* .main-dashboard-wrapper {
        max-width: 720px;
    } */

    .page-sidebar {
        max-width: 230px;
    }

    button.log-out-button {
        min-width: 120px;
        max-width: 120px;
    }

    .page-wrapper {
        max-width: 480px;
        width: 100%;
        min-width: 100%;
    }
    
}

@media screen and (max-width:767px) {
    /* .main-dashboard-wrapper {
        max-width: 540px;
    } */

    /* .header-right {
        margin-top: 20px;
    } */

    /* .content-wrapper {
        height: calc(100vh - 138px);
    } */

    .info-block img {
        width: 50px;
        height: 45px;
    }

    .header-wrapper {
        padding: 20px;
        margin-left: 70px;
    }

    .log-out-block {
        padding: 15px;
    }

    button.log-out-button {
        min-width: 120px;
        max-width: 120px;
    }

    .page-wrapper {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .hide-bar {
        display: none;
    }

    .mobile-menu {
        transition: all 0.6s ease;
    }

    .page-sidebar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #205e83;
        opacity: 0;
        transition: all 0.6s ease;
        z-index: 1;
        padding: 40px 0 0;
        height: 100vh;
    }

    .open-menu.mobile-menu .page-sidebar {
        opacity: 1;
        transition: all 0.6s ease;
        left: 0;
        display: block;
    }

    .open-menu.mobile-menu svg.close-nav {
        transition: all 0.6s ease;
        display: block;
        fill: #fff;
    }

    .mobile-menu svg.nav-menu {
        display: block;
    }

    .open-menu.mobile-menu svg.nav-menu {
        display: none;
    }


    .page-title {
        margin-left: 50px;
        padding-top: 7px;
    }

    .navigation {
        height: calc(100vh - 355px);
    }

}


@media screen and (max-width:575px) {
    /* .main-dashboard-wrapper {
        max-width: 100%;
    } */

    .content-wrapper {
        height: calc(100vh - 148px) !important;
    }

    .dropdown span {
        right: -10px;
    }

    .main-nav ul li {
        padding: 7px 0;
    }

    /* .main-nav ul li a, .main-nav ul li span.nav-span {
        min-height: 25px;
    } */

    .navigation {
        height: calc(100vh - 405px);
    }

    .header-right {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mobile-menu svg.close-nav, .mobile-menu svg.nav-menu {
        top: 25px;
    }

    /* .page-title {
        margin-left: 30px;
    }

    .page-title h6 svg {
        margin-right: 0;
    }

    .header-right ul {
        gap: 10px;
    }

    ul.page-info li button.btn.button-green {
        max-width: 100px;
        padding: 10px 7px;
    }

    ul.page-info li .btn.button-green svg {
        margin-left: 3px;
    }

    .mobile-menu svg.close-nav, .mobile-menu svg.nav-menu {
        left: 20px;
    }

    .header-wrapper {
        padding: 20px 10px;
    } */
}
.header-wrapper button.btn.button-secondry {
    justify-content: center;
    background-color: #fff;
    color: #37657F;
    border: 2px solid #37657F;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 16px;
    text-align: center;
    border-radius: 8px;
}

.header-wrapper .button-secondry svg {
    fill: #37657F;
    margin-left: 8px;
}